import React, { useState } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import {
  PieChart, Pie, Sector, ResponsiveContainer, Cell, Tooltip
} from 'recharts';
import _ from 'lodash';
import moment from 'moment';
import "../../../styles/styles.scss";

const totalCosts = 1950 + 290.46 + 1954.16 + 2005.5 + 2751.88 + 3252.07 + 3538.44;

const chartData = [
  {
    name: 'Adoption',
    value: 1950,
  },
  {
    name: 'Insurance',
    value: 290.46
  },
  {
    name: 'Vet Visits',
    value: 1954.16
  },
  {
    name: 'Food, Treats, and Chews',
    value: 2005.5
  },
  {
    name: 'Toys, Supplies, and Misc',
    value: 2751.88
  },
  {
    name: 'Training',
    value: 3252.07,
  }, 
  {
    name: 'Daycare and Boarding',
    value: 3538.44
  }
];

const renderActiveShape = (props) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props;
  

  return (
    <g>
      <text x={cx} y={cy} textAnchor="middle" fill="#333">{`$${totalCosts}`}</text>
      <text x={cx} y={cy} dy={16} textAnchor="middle" fill="#333" fontSize="10px">spent in 6 months</text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
    </g>
  );
};

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#6554C0', '#FF5630', '#00C7E6'];

const DogCostBreakDown = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div style={{ margin: '-20px', height: '500px' }}>
      <ResponsiveContainer width="100%" height={500}>
        <PieChart>
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape} 
            data={chartData} 
            innerRadius={60}
            outerRadius={80} 
            fill="#8884d8"
            onMouseEnter={(data, index) => setActiveIndex(index)}
          >
            {chartData.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)}
          </Pie>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}

export default DogCostBreakDown
